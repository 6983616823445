import clsx from "clsx"
import * as React from "react"

const MarketCard = ({ children, variant, className }) => (
  <div className={clsx(`market-card market-card-${variant}`, className)}>
    {children}
  </div>
)

export default MarketCard
