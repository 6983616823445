import * as React from "react"
import { Container } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"
import lottie from "lottie-web"
import flatTube from "./../../images/flat-tube.png"
import flatTube2 from "./../../images/flat-tube-2.png"
import flatTube3 from "./../../images/flat-tube-3.png"
import isometricPyramid from "./../../animations/isometric-pyramid.json"
import miniTricubeSpinner from "./../../animations/mini-tricube-spinner.json"
import threeXThreeCubesLoader from "./../../animations/3x3-cubes-loader.json"

SwiperCore.use([Pagination])

const RoadmapSection = () => {
  let isometricPyramidRef = React.createRef()
  let miniTricubeSpinnerRef = React.createRef()
  let threeXThreeCubesLoaderRef = React.createRef()

  const lottieConfig = {
    renderer: "svg",
    loop: true,
    autoplay: true,
  }

  React.useEffect(() => {
    const isometricPyramidAnim = lottie.loadAnimation({
      container: isometricPyramidRef.current,
      animationData: isometricPyramid,
      ...lottieConfig,
    })
    const miniTricubeSpinnerAnim = lottie.loadAnimation({
      container: miniTricubeSpinnerRef.current,
      animationData: miniTricubeSpinner,
      ...lottieConfig,
    })
    const threeXThreeCubesLoaderAnim = lottie.loadAnimation({
      container: threeXThreeCubesLoaderRef.current,
      animationData: threeXThreeCubesLoader,
      ...lottieConfig,
    })

    return () => {
      isometricPyramidAnim.destroy()
      miniTricubeSpinnerAnim.destroy()
      threeXThreeCubesLoaderAnim.destroy()
    }
  }, [])

  return (
    <div className="roadmap-section gx-4">
      <div className="ellipse-1">
        <svg
          width="344"
          height="829"
          viewBox="0 0 344 829"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.05"
            cx="414.5"
            cy="414.5"
            r="399.5"
            stroke="#FF5E49"
            strokeWidth="30"
          />
        </svg>
      </div>
      <Container id="roadmap">
        <h2 className="text-red">Roadmap</h2>
        <h3>Plans for the future</h3>
        <div className="px-lg-5">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={40}
            pagination={{ clickable: true }}
            breakpoints={{ 769: { slidesPerView: 3, spaceBetween: 120 } }}
          >
            <SwiperSlide>
              <h2 className="text-red">Stage 1</h2>
              <div className="illustration">
                <svg
                  width="350"
                  height="101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="line"
                >
                  <line
                    x1="0"
                    y1="0"
                    x2="600"
                    y2="0"
                    stroke="#FFF7F2"
                    strokeWidth="40"
                  />
                </svg>
                <img src={flatTube} id="flat-1" alt="flat tube illustration" />
                <div
                  className="isometric-pyramid-container"
                  ref={isometricPyramidRef}
                />
              </div>
              <h3 className="text-red">Lift off</h3>
              <small>Jan/Feb 2022</small>
              <p>
                <a
                    href="https://medium.com/@fr0ck/fractional-rocket-launch-and-tokenomics-b496e47d11da"
                    target="_blank"
                    rel="noreferrer"
                    className={"text-red"}
                >
                  Fractional Rocket will be launched in 3 phases.
                </a>
                <ul>
                  <li>
                    <a
                      href="https://medium.com/@fr0ck/fractional-rocket-community-sale-in-depth-16b1703bbfcb"
                      target="_blank"
                      rel="noreferrer"
                      className={"text-red"}
                  >February 12th: Community sale - SOLD OUT.</a></li>
                  <li>
                    <a
                        href="https://medium.com/@fr0ck/fractional-rocket-public-sale-in-depth-c4fe57606611"
                        target="_blank"
                        rel="noreferrer"
                        className={"text-red"}
                    >February 19th: Public sale - SOLD OUT.</a></li>
                  <li>
                    <a
                        href="https://medium.com/@fr0ck/fractional-rocket-protocol-launch-stealth-launch-in-depth-a2a68ac07a17"
                        target="_blank"
                        rel="noreferrer"
                        className={"text-red"}
                    >
                      February 23d: Stealth launch.
                    </a>
                  </li>
                </ul>
              </p>
              {/*<a*/}
              {/*    href="https://calc.fractionalrocket.money/"*/}
              {/*    target="_blank"*/}
              {/*    rel="noreferrer"*/}
              {/*    className={"text-red"}*/}
              {/*>Open the Launch Calculator.</a>*/}
            </SwiperSlide>
            <SwiperSlide>
              <h2 className="text-red">Stage 2</h2>
              <div className="illustration">
                <svg
                  width="350"
                  height="101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="line"
                >
                  <line
                    x1="0"
                    y1="0"
                    x2="600"
                    y2="0"
                    stroke="#FFF7F2"
                    strokeWidth="40"
                  />
                </svg>
                <img src={flatTube2} id="flat-2" alt="flat tube illustration" />
                <div
                  className="mini-tricube-spinner-container"
                  ref={miniTricubeSpinnerRef}
                />
              </div>
              <h3 className="text-red">Rocket speed</h3>
              <small>Feb/March 2022</small>
              <p>
                Increase node treasury, scale operations, improve dashboarding and increase marketing.
              </p>

            </SwiperSlide>
            <SwiperSlide>
              <h2 className="text-red">Stage 3</h2>
              <div className="illustration">
                <img src={flatTube3} id="flat-3" alt="flat tube illustration" />
                <div
                  className="three-x-three-cubes-loader-container"
                  ref={threeXThreeCubesLoaderRef}
                />
              </div>
              <h3 className="text-red">Escape velocity</h3>
              <small>April/May 2022</small>
              <ul>
                <li>Investment in other hard nodes.<br/>
                  The <span className="text-red">$FROCK</span> holders have voted to diversify the treasury into:</li>
                <ul>
                  {/*<li style={{ 'margin-left': '25px' }}>Phoenix Community Capital: $FIRE nodes</li>*/}
                  {/*<li style={{ 'margin-left': '25px' }}>Constellation network: $DAG nodes</li>*/}
                  <li style={{ 'margin-left': '25px' }}>Nodeify: $NDFI nodes</li>
                </ul>

              </ul>
            </SwiperSlide>
          </Swiper>
        </div>
      </Container>
    </div>
  )
}

export default RoadmapSection
