import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/home/hero-section"
import WhySection from "../components/home/why-section"
import CompoundingCalculatorSection from "../components/home/compounding-calculator/compounding-calculator-section"
import RoadmapSection from "../components/home/roadmap-section"
import OurTeamSection from "../components/home/our-team-section"
import FractionalNodeSection from "../components/home/fractional-node-section"
import EarningsCalculatorSection from "../components/home/earnings-calculator-section"
import ProjectedTreasuryReturnSection from "../components/home/projected-treasury-return-section"

const IndexPage = () => {
  const [strongPrice, setStrongPrice] = React.useState(250)
  const [dagPrice, setDagPrice] = React.useState(0.18)
  const [firePrice, setFirePrice] = React.useState(100)

  React.useEffect(() => {
    const getStrongPrice = async () => {
      const response = await fetch("/.netlify/functions/get-strong-price")
      const result = await response.json()

      let usd = 250

      if (response.status !== 200) {
        console.error({ error: result })
      } else {
        usd = Math.round(Number(result.usd))
      }

      setStrongPrice(usd)
    }

      const getDagPrice = async () => {
          const response = await fetch("/.netlify/functions/get-dag-price")
          const result = await response.json()

          let usd = 0.18

          if (response.status !== 200) {
              console.error({ error: result })
          } else {
              usd = Math.round(Number(result.usd))
          }

          setDagPrice(usd)
      }

      const getFirePrice = async () => {
          const response = await fetch("/.netlify/functions/get-fire-price")
          const result = await response.json()

          let usd = 100

          if (response.status !== 200) {
              console.error({ error: result })
          } else {
              usd = Math.round(Number(result.usd))
          }

          setFirePrice(usd)
      }

      getStrongPrice()
      getFirePrice()
      getDagPrice()
  }, [])

  return (
    <Layout>
      <Seo title="Fractional Rocket" />
      <HeroSection strongPrice={strongPrice} dagPrice={dagPrice} firePrice={firePrice} />
      {/*<WhySection />*/}
      {/*<CompoundingCalculatorSection defaultStrongPrice={strongPrice} />*/}
      {/*<FractionalNodeSection defaultStrongPrice={strongPrice} />*/}
      {/*<RoadmapSection />*/}
      {/*<ProjectedTreasuryReturnSection defaultStrongPrice={strongPrice} />*/}
      {/*<EarningsCalculatorSection />*/}
      {/*<OurTeamSection />*/}
    </Layout>
  )
}

export default IndexPage
