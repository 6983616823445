import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import lottie from "lottie-web"
import { LightLinkButton } from "../buttons"
import piechartAnimation from "../../animations/homepage-3d-piechart.json"
import MarketCard from "../cards/market-card"

const REWARDS_PER_DAY = 0.09;
const FIRE_REWARDS_PER_DAY = 0.225;

const HeroSection = ({ strongPrice, dagPrice, firePrice }) => {
  const [nodesGenerated, setNodesGenerated] = React.useState(0)
  const [fireNodesGenerated, setFireNodesGenerated] = React.useState(0)
  const [dagNodesGenerated, setDagNodesGenerated] = React.useState(0)

  let piechartAnimationContainer = React.createRef()

  React.useEffect(() => {
    const piechartAnim = lottie.loadAnimation({
      container: piechartAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: piechartAnimation,
    })

    const getNodesGenerated = async () => {
      const response = await fetch(
        "/.netlify/functions/get-reward-distributions"
      )
      const data = await response.json()
      const nodes = data.nodes_generated ?? 0
          const firenodes = data.fire_nodes_generated ?? 0
          const dagnodes = data.dag_nodes_generated ?? 0

          setDagNodesGenerated(Number(dagnodes));
          setFireNodesGenerated(Number(firenodes));
          setNodesGenerated(Number(nodes));
    }

    ;(async () => {
      await getNodesGenerated()
    })()

    return () => piechartAnim.destroy()
  }, [])

  return (
    <div id="home" className="hero-section gx-4">
      <div className="ellipse-1">
        <svg
          width="715"
          height="649"
          viewBox="0 0 715 649"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M1095 95C1095 386.905 855.727 624 560 624C264.273 624 25 386.905 25 95C25 -196.905 264.273 -434 560 -434C855.727 -434 1095 -196.905 1095 95Z"
            stroke="#FF5E49"
            strokeWidth="50"
          />
        </svg>
      </div>
      <div className="ellipse-2">
        <svg
          width="173"
          height="347"
          viewBox="0 0 173 347"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.4"
            cx="-0.5"
            cy="173.5"
            r="158.5"
            stroke="#FF5E49"
            strokeWidth="30"
          />
        </svg>
      </div>
      <Container>
        <Row className="mt-5">
          <Col lg="6" className="content">
            <h1>Private<br />investment club</h1>
            <h2>
              Capital acquired<br /> during 2022 node season.
            </h2>
            <p>
              Join our Discord for more information.
            </p>
          </Col>
          <Col className="header-right" lg={{ span: 5, offset: 1 }}>
            {/*<div*/}
            {/*  className="piechart-animation-container"*/}
            {/*  ref={piechartAnimationContainer}*/}
            {/*/>*/}

            <MarketCard variant="primary" className="mb-3">
              <h3>
                  <strong>Somnio NFT Treasury:</strong><br/>
                  2 Sapphire <br />
                  10 Ruby <br />

                {/*    {nodesGenerated} $STRNGR nodes<br/>*/}
                {/*    {fireNodesGenerated} $FIRE node<br/>*/}
                {/*    {dagNodesGenerated} $DAG node<br />*/}
                {/*  generating US${" "}*/}
                {/*{new Intl.NumberFormat("en-US", {*/}
                {/*  maximumFractionDigits: 2,*/}
                {/*  minimumFractionDigits: 2,*/}
                {/*}).format((nodesGenerated * REWARDS_PER_DAY * strongPrice) + (fireNodesGenerated * FIRE_REWARDS_PER_DAY * firePrice) )}{" "}*/}
                {/*/ day!*/}
              </h3>
            </MarketCard>

            {/*<MarketCard variant="primary">*/}
            {/*  /!*<div>*!/*/}
            {/*  /!*  <p>Market Cap</p>*!/*/}
            {/*  /!*  <h3>$3,769,091</h3>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*  /!*<div>*!/*/}
            {/*  /!*  <p>Dividend paid</p>*!/*/}
            {/*  /!*  <h3>$217,132</h3>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*  /!*<div>*!/*/}
            {/*  /!*  <p>Volume (24h)</p>*!/*/}
            {/*  /!*  <h3>$43,466</h3>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*  /!*<h3>*!/*/}
            {/*  /!*  <a*!/*/}
            {/*  /!*    href="https://medium.com/@fr0ck/fractional-rocket-protocol-stealth-launch-trade-instructions-7c807803bc0"*!/*/}
            {/*  /!*    target="_blank"*!/*/}
            {/*  /!*    className="text-white"*!/*/}
            {/*  /!*  >*!/*/}
            {/*  /!*    Use 30% slippage*!/*/}
            {/*  /!*  </a>*!/*/}
            {/*  /!*</h3>*!/*/}
            {/*</MarketCard>*/}
            <a
              href="https://discord.gg/fWRs9DvHAp"
              className="button button-light button-round"
              style={{
                paddingLeft: "2.5rem",
                paddingRight: "2.5rem",
                marginTop: "30px",
                marginRight: "25px",
              }}
              target="_blank"
            >
              Discord
            </a>

            {/*<a href="https://medium.com/@fr0ck/"*/}
            {/*   className="button button-light button-round"*/}
            {/*   style={{*/}
            {/*       paddingLeft: "2.5rem",*/}
            {/*       paddingRight: "2.5rem",*/}
            {/*       marginTop: "30px",*/}
            {/*   }}*/}
            {/*   target="_blank"*/}
            {/*>*/}
            {/*    Medium</a>*/}



            {/*<LightLinkButton*/}
            {/*    to="https://discord.gg/DprMJTMgZb"*/}
            {/*    variant="round"*/}
            {/*    style={{*/}
            {/*        paddingLeft: "2.5rem",*/}
            {/*        paddingRight: "2.5rem",*/}
            {/*        marginTop: "30px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    /!*Buy $FROCK*!/*/}

            {/*    Discord*/}
            {/*</LightLinkButton>*/}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HeroSection
